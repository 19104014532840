const dev = {
  API_URL: "https://test-website-api.inkastle.studio/website",
  // API_URL: "http://localhost:8080/website",
  recaptcha: "6Ld5ONomAAAAAOMXEzOlnZ0WmqzLbpVmD2x9EK_u",
  analytics: "G-MKF35JQWDW",
};

const prod = {
  API_URL: "https://website-api.inkastle.studio/website",
  recaptcha: "6Ld5ONomAAAAAOMXEzOlnZ0WmqzLbpVmD2x9EK_u",
  analytics: "G-MKF35JQWDW",
};

export const config = process.env.REACT_APP_ENV === "development" ? dev : prod;
