const initialState = {
  isLoading: true, // this is a flag that will be used to show a loading spinner
  error: null,
  websiteData: {
    header: {
      tabs: [
        {
          name: "Home",
          path: "",
          background_image_url: "",
          background_image_mobile_url: "",
        },
      ],
      heroContent: {
        title: "",
        text: "",
        button: {
          text: "",
          path: "",
        },
        about_us: {
          title: "",
          text: [],
        },
      },
    },
    team: {
      title: "",
      text: "",
      section_side_img: "",
      members: [
        {
          name: "",
          description: "",
          image: "",
        },
      ],
    },
    preview: {
      title: "",
      description: "",
      button_title: "",
      button_location: "",
      images: [],
    },
    statistics: [
      {
        title: "",
        count: 0,
        measure: null,
      },
    ],
    ourClients: {
      content: {
        title: "",
        section_side_img: "",
      },
      testimonials: [
        {
          name: "",
          comment: "",
        },
      ],
    },
    partners: [
      {
        altText: "alt text",
        image: "",
      },
    ],
    footer: {
      socialNetworks: [{ name: "", path: "" }],
      footerContent: {
        info: {},
        background_image_url: "",
      },
    },
    contacts: {
      addressLine1: "",
      addressLine2: "",
      email: "",
      phone: "",
    },
    gamesSection: {
      title: "",
      description: "",
      gamePreviews: [],
    },
  },
};

export { initialState };
