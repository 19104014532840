import React, { useState, useEffect, useRef } from "react";
import classes from "./Statistics.module.scss";

const CountUp = ({ start, end, duration, measure }) => {
  const [count, setCount] = useState(0);
  const [startCounting, setStartCounting] = useState(false);
  const step = (end - start) / duration;
  const elementRef = useRef(null);

  useEffect(() => {
    const onScroll = () => {
      if (
        !startCounting &&
        elementRef.current &&
        elementRef.current.getBoundingClientRect().top <= window.innerHeight
      ) {
        setStartCounting(true);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [startCounting]);

  useEffect(() => {
    if (startCounting && count < end) {
      const intervalId = setInterval(() => {
        setCount((prevCount) => prevCount + step);
      }, 20);
      return () => clearInterval(intervalId);
    }
  }, [count, end, startCounting, step]);

  return (
    <span className={`${classes.number}`} ref={elementRef}>
      {startCounting ? Math.round(count) : start}
      {measure ? <span>{measure}</span> : null}
    </span>
  );
};

export default CountUp;
