import React from "react";
import "./Careers.scss";

const CareersSubmited = () => {
  return (
    <>
      {" "}
      <div className="sub-navigation white-container">
        <div className="zigzag-top" />
        <div className="wrapper smaller">
          <ul>
            <li>
              <a href="#graphic-designer" title="Graphic designer">
                Graphic designer
              </a>
            </li>
            <li>
              <a href="#animator" title="Animator">
                Animator
              </a>
            </li>
          </ul>
        </div>
      </div>
      <section className="careers creme-container">
        <div className="zigzag-bottom" />
        <div className="wrapper smaller">
          <div className="career-container" id="graphic-designer">
            <h2 className="section-title">Graphic designer</h2>
            <div className="flex-container two-column">
              <div className="flex-item">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Facilisis rhoncus nisi quis blandit a elit enim. Volutpat
                  feugiat dolor pulvinar porttitor in convallis blandit
                  vulputate. Malesuada accumsan odio eu cras in amet porta non.
                  Massa eget vulputate cras iaculis volutpat tellus at praesent
                  erat. Mauris aliquam, scelerisque egestas velit viverra.
                  Tristique neque nibh diam nullam nunc magnis accumsan
                  tristique. Quisque congue nisl proin cras dictum tellus
                  cursus. Dui pulvinar sed eget amet enim gravida aliquet neque.
                </p>
                <p>
                  Faucibus dolor volutpat nibh in nisi. Congue ac vitae fusce
                  libero suspendisse in proin. Sit ornare consectetur maecenas
                  varius feugiat non. Dictum non consectetur massa faucibus
                  vulputate eget lectus. Magna tortor diam, tempor mauris nunc.
                  Nibh fringilla nec eget risus fringilla sollicitudin pulvinar.
                  Eu est nunc nullam nisi, velit dignissim. Eget at blandit
                  faucibus dapibus nisl sodales ornare feugiat dignissim. Luctus
                  neque, orci, massa, odio iaculis ultrices egestas. Magna
                  viverra convallis justo, enim quis. Mauris, et morbi donec at
                  suspendisse est massa et facilisis. Auctor nunc, ornare nunc
                  quam ut. Feugiat sed laoreet morbi pretium cras purus potenti
                  amet, neque. Volutpat ut interdum mauris sed phasellus
                  placerat in.
                </p>
                <p>
                  Eu, ac enim sit mauris suscipit luctus risus. Sit sit in
                  gravida sed consectetur quis sem dapibus. Varius libero, diam
                  diam, amet. Vivamus et molestie neque egestas nibh. Malesuada
                  mattis odio sit euismod risus, risus scelerisque nulla
                  imperdiet. Pretium duis consectetur vestibulum in dignissim.
                  Facilisis quis leo bibendum ut cras.
                </p>
                <p>
                  Eget at blandit faucibus dapibus nisl sodales ornare feugiat
                  dignissim. Luctus neque, orci, massa, odio iaculis ultrices
                  egestas. Magna viverra convallis justo, enim quis. Mauris, et
                  morbi donec at suspendisse est massa et facilisis. Auctor
                  nunc, ornare nunc quam ut. Feugiat sed laoreet morbi pretium
                  cras purus potenti amet, neque. Volutpat ut interdum mauris
                  sed phasellus placerat in.
                </p>
              </div>
              <div className="flex-item">
                <div className="submit-container">
                  <div className="submit-title">
                    <h3>thank you for your application!</h3>
                  </div>
                  <div className="submit-content">
                    <p>
                      We have sent a confirmation email to the address you
                      provided. We will review your application and contact you
                      if we think you would be good fit for the job.
                    </p>
                    <p>
                      In the meanwhile, would you like to have a look at the
                      work we produce for our clients:
                    </p>
                    <a
                      href="portfolio.html"
                      title="see our portfolio"
                      class="btn dark-purple"
                    >
                      see our portfolio
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="career-container" id="animator">
            <h2 className="section-title">Animator</h2>
            <div className="flex-container two-column">
              <div className="flex-item">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Facilisis rhoncus nisi quis blandit a elit enim. Volutpat
                  feugiat dolor pulvinar porttitor in convallis blandit
                  vulputate. Malesuada accumsan odio eu cras in amet porta non.
                  Massa eget vulputate cras iaculis volutpat tellus at praesent
                  erat. Mauris aliquam, scelerisque egestas velit viverra.
                  Tristique neque nibh diam nullam nunc magnis accumsan
                  tristique. Quisque congue nisl proin cras dictum tellus
                  cursus. Dui pulvinar sed eget amet enim gravida aliquet neque.
                </p>
                <p>
                  Faucibus dolor volutpat nibh in nisi. Congue ac vitae fusce
                  libero suspendisse in proin. Sit ornare consectetur maecenas
                  varius feugiat non. Dictum non consectetur massa faucibus
                  vulputate eget lectus. Magna tortor diam, tempor mauris nunc.
                  Nibh fringilla nec eget risus fringilla sollicitudin pulvinar.
                  Eu est nunc nullam nisi, velit dignissim. Eget at blandit
                  faucibus dapibus nisl sodales ornare feugiat dignissim. Luctus
                  neque, orci, massa, odio iaculis ultrices egestas. Magna
                  viverra convallis justo, enim quis. Mauris, et morbi donec at
                  suspendisse est massa et facilisis. Auctor nunc, ornare nunc
                  quam ut. Feugiat sed laoreet morbi pretium cras purus potenti
                  amet, neque. Volutpat ut interdum mauris sed phasellus
                  placerat in.
                </p>
                <p>
                  Eu, ac enim sit mauris suscipit luctus risus. Sit sit sit in
                  gravida sed consectetur quis sem dapibus. Varius libero, diam
                  diam, amet. Vivamus et molestie neque egestas nibh. Malesuada
                  mattis odio sit euismod risus, risus scelerisque nulla
                  imperdiet. Pretium duis consectetur vestibulum in dignissim.
                  Facilisis quis leo bibendum ut cras.
                </p>
                <p>
                  Eget at blandit faucibus dapibus nisl sodales ornare feugiat
                  dignissim. Luctus neque, orci, massa, odio iaculis ultrices
                  egestas. Magna viverra convallis justo, enim quis. Mauris, et
                  morbi donec at suspendisse est massa et facilisis. Auctor
                  nunc, ornare nunc quam ut. Feugiat sed laoreet morbi pretium
                  cras purus potenti amet, neque. Volutpat ut interdum mauris
                  sed phasellus placerat in.
                </p>
              </div>
              <div className="flex-item">
                <div className="form">
                  <h3 className="form-title">apply now:</h3>
                  <form action="./careers_submitted">
                    <div className="form-field">
                      <label htmlFor="name2">Your name</label>
                      <input
                        type="text"
                        id="name2"
                        placeholder="Name Surname"
                      />
                    </div>
                    <div className="form-field">
                      <label htmlFor="email2">Your email</label>
                      <input type="email" id="email2" placeholder="Email" />
                    </div>
                    <div className="form-field">
                      <label htmlFor="phone2">Your telephone number</label>
                      <input
                        type="text"
                        id="phone2"
                        placeholder="+359 ХХХ ХХХ ХХХ"
                      />
                    </div>
                    <div className="form-field">
                      <label htmlFor="portfolio2">Your online portfolio</label>
                      <input
                        type="text"
                        id="portfolio2"
                        placeholder="Behance, Dribbble, personal website"
                      />
                    </div>
                    <div className="form-field">
                      <label htmlFor="upload2">Upload your CV</label>
                      <input type="file" id="upload2" />
                    </div>
                    <div className="form-field">
                      <label htmlFor="message2">Leave a message</label>
                      <textarea
                        id="message2"
                        rows="10"
                        placeholder="Anything to add? Say it here."
                      ></textarea>
                    </div>
                    <div className="form-field">
                      <input
                        type="submit"
                        value="send application"
                        className="btn dark-purple"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareersSubmited;
