import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./websiteDataInitState";
import { config } from "../common/Constants";

import axios from "axios";

const fetchWebsiteData = createAsyncThunk(
  "websiteData/fetchWebsiteData",
  async () => {
    const response = await axios.get(`${config.API_URL}`);
    return response.data;
  }
);

const websiteDataSlice = createSlice({
  name: "websiteData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebsiteData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.websiteData = action.payload;
      })
      .addCase(fetchWebsiteData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchWebsiteData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const websiteDataActions = websiteDataSlice.actions;

export default websiteDataSlice;

export { fetchWebsiteData };
