import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import PortfolioCarousel from "../components/PortfolioCarousel/PortfolioCarousel";
import ScrollToTopButton from "../components/ScrollToTop/ScrollToTopButton";
import ReactGA from "react-ga4";
import "./Portfolio.scss";

const Portfolio = () => {
  const loaderData = useLoaderData();
  const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/portfolio",
      title: "Portfolio",
    });

    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setShowScrollToTopButton(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClickScroll = (elementId) => {
    console.log("click");
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div className="sub-navigation white-container">
        <div className="zigzag-top" />
        <div className="wrapper smaller">
          <ul>
            {loaderData.data.map((portfolioElement, index) => {
              const transformedTitle = portfolioElement.title
                .toLowerCase()
                .replace(/[\s-]+/g, "-");

              return (
                <li key={index}>
                  <div
                    onClick={() => {
                      handleClickScroll(transformedTitle);
                    }}
                    title={portfolioElement.title}
                  >
                    {portfolioElement.title}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <section className="portfolio creme-container">
        <div className="zigzag-bottom" />
        <div className="wrapper">
          {loaderData.data.map((portfolioElement, index) => {
            const transformedTitle = portfolioElement.title
              .toLowerCase()
              .replace(/[\s-]+/g, "-");

            return (
              <PortfolioCarousel
                key={index}
                portfolioElement={portfolioElement}
                transformedTitle={transformedTitle}
              />
            );
          })}
        </div>
      </section>

      {showScrollToTopButton && <ScrollToTopButton />}
    </div>
  );
};

export default Portfolio;
