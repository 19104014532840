import React from "react";
import classes from "./Partners.module.scss";

import { useSelector } from "react-redux";

const Partners = () => {
  const partners = useSelector(
    (state) => state.websiteData.websiteData.partners
  );

  const backgroundUrl = partners?.backgroundImage;

  return (
    <section
      className={classes["partners-section"]}
      style={{ backgroundImage: `url(${backgroundUrl})` }}
    >
      <div className="wrapper">
        <div className={`${classes.partners}`}>
          {partners.partners?.map((partner, i) => {
            return (
              <div key={i} className={classes.partner}>
                <img src={partner.image} alt={partner.altText} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Partners;
