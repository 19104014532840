import { React, useEffect, useState } from "react";

import "../../pages/Portfolio.scss";

import { Swiper, SwiperSlide } from "swiper/react";

const ProtfolioCarousel = (props) => {
  const [swiper, setSwiper] = useState(null);
  const [paginationText, setPaginationText] = useState(
    `1 / ${props.portfolioElement.images.length}`
  );

  const { portfolioElement, transformedTitle } = props;

  useEffect(() => {
    if (swiper) {
      swiper.on("slideChange", () => {
        setPaginationText(
          `${swiper.activeIndex + 1} / ${swiper.slides.length}`
        );
      });
    }
  }, [swiper]);

  return (
    <div
      key={portfolioElement.id}
      id={transformedTitle}
      className={`carousel-container ${
        portfolioElement.id % 2 === 0 ? "right" : "left"
      }`}
    >
      <div className="carousel slick-initialized slick-slider">
        <div className="carousel slick-initialized slick-slider">
          <button
            className="slick-prev slick-arrow"
            style={{ display: "block" }}
            onClick={() => swiper.slidePrev()}
          />
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            onSwiper={setSwiper}
            onSlideChange={props.onSlideChange}
          >
            {portfolioElement.images.map((image, index) => {
              return (
                <SwiperSlide
                  key={index}
                  className="carousel-item"
                >
                  <img src={image}
                    loading="lazy"
                    onLoad={(event) => event.target.classList.add("loaded")}
                    alt=''
                  />
                  <div className="swiper-lazy-preloader"></div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <button
            className="slick-next slick-arrow"
            style={{ display: "block" }}
            onClick={() => swiper.slideNext()}
          />
        </div>
      </div>
      <div className="carousel-description">
        <p>{portfolioElement.description}</p>
      </div>
      <div className="carousel-square">
        <h2>{portfolioElement.title}</h2>
        <p className="mobile-carousel-description">
          {portfolioElement.description}
        </p>
        <div className="pagingInfo">{paginationText}</div>
      </div>
    </div>
  );
};

export default ProtfolioCarousel;
