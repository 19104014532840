import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";
import "./Contacts.scss";
import mailIcon from "./../assets/img/mail.png";
import pinIcon from "./../assets/img/pin.png";
import phoneIcon from "./../assets/img/phone.png";
import { config } from "../common/Constants";

import ScrollToTopButton from "../components/ScrollToTop/ScrollToTopButton";
import ReactGA from "react-ga4";

const Contact = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    return await executeRecaptcha("query");
  };

  const contactsInfo = useSelector(
    (state) => state.websiteData.websiteData.contacts
  );

  const url = `${config.API_URL}/contact/query`;

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/contacts",
      title: "Contacts",
    });

    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setShowScrollToTopButton(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      scrollToTop("contact-form");
      return;
    }

    try {
      setIsRequestPending(true);
      const requestData = {
        name,
        email,
        message,
      };

      const recaptchaToken = await handleReCaptchaVerify();

      await axios.post(url, requestData, {
        headers: { recaptcha: recaptchaToken },
      });

      setIsFormSubmitted(true);
    } catch (error) {
      console.error("There was a problem with the request: ", error);
      scrollToTop("contact-form");
    }

    setIsRequestPending(false);
  };

  const validateForm = () => {
    let errors = {};
    const onlyLettersRegex = /^[A-Za-z\s]+$/;

    if (name.trim().split(" ").length < 2 || !onlyLettersRegex.test(name)) {
      errors.name = "Please enter both first and last name using only letters";
    }

    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      errors.email = "Please enter a valid email address.";
    }

    if (message.trim() === "") {
      errors.message = "Please enter a message.";
    }

    return errors;
  };

  const scrollToTop = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setMessage("");
    setIsFormSubmitted(false);
    setIsRequestPending(false);
    setFormErrors({});
  };

  return (
    <div>
      <section className="contacts creme-container">
        <div className="zigzag-top" />
        <div className="zigzag-bottom" />
        <div className="wrapper smaller">
          <div className="contacts-container">
            <div className="flex-container two-column">
              <div className="flex-item">
                <ul>
                  <li>
                    <span>
                      <img src={pinIcon} alt="Pin" />
                    </span>
                    <p>
                      {contactsInfo.addressLine1}
                      <br />
                      {contactsInfo.addressLine2}
                    </p>
                  </li>
                  <li>
                    <span>
                      <img src={mailIcon} alt="Mail" />
                    </span>
                    <a href={`mailto:${contactsInfo.email}`}>
                      {contactsInfo.email}
                    </a>
                  </li>
                  <li>
                    <span>
                      <img src={phoneIcon} alt="Phone" />
                    </span>
                    <a href={`tel:${contactsInfo.phone}`}>
                      {contactsInfo.phone}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item">
                {isFormSubmitted ? (
                  <>
                    <div className="form thankyou">
                      <h2 className="form-title">thank you!</h2>
                      <p>Your message has been sent.</p>
                    </div>
                    <div
                      onClick={resetForm}
                      className="btn dark-purple reset-btn"
                    >
                      reset form
                    </div>
                  </>
                ) : (
                  <div className="form" id="contact-form">
                    <h2 className="form-title">drop us a line:</h2>
                    <form onSubmit={handleSubmit}>
                      <div className="form-field">
                        <label htmlFor="name">
                          Your name<span className="required-star">*</span>
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value.slice(0, 30))}
                          placeholder="Name Surname"
                          className={`form-input ${
                            formErrors.name ? "error" : ""
                          }`}
                          style={{ color: formErrors.name ? "black" : "" }}
                        />
                        {formErrors.name && (
                          <div className="error-message">{formErrors.name}</div>
                        )}
                      </div>
                      <div className="form-field">
                        <label htmlFor="email">
                          Your email<span className="required-star">*</span>
                        </label>
                        <input
                          type="email"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                          className={`form-input ${
                            formErrors.email ? "error" : ""
                          }`}
                          style={{ color: formErrors.email ? "black" : "" }}
                        />
                        {formErrors.email && (
                          <div className="error-message">
                            {formErrors.email}
                          </div>
                        )}
                      </div>
                      <div className="form-field">
                        <label htmlFor="message">
                          Leave a message
                          <span className="required-star">*</span>
                        </label>
                        <textarea
                          id="message"
                          value={message}
                          onChange={(e) =>
                            setMessage(e.target.value.slice(0, 1000))
                          }
                          rows="10"
                          placeholder="Anything to add? Say it here."
                          maxLength="1000"
                          className={`form-input ${
                            formErrors.message ? "error" : ""
                          }`}
                          style={{ color: formErrors.message ? "black" : "" }}
                        ></textarea>
                        {formErrors.message && (
                          <div className="error-message">
                            {formErrors.message}
                          </div>
                        )}
                      </div>

                      <div className="form-field">
                        <input
                          disabled={isRequestPending}
                          type="submit"
                          value="send"
                          className={`btn ${
                            isRequestPending ? "disabled" : "dark-purple"
                          }`}
                        />
                      </div>
                    </form>
                    <div>
                      <small>
                        This site is protected by reCAPTCHA and the Google{" "}
                        <a href="https://policies.google.com/privacy">
                          Privacy Policy
                        </a>{" "}
                        and{" "}
                        <a href="https://policies.google.com/terms">
                          Terms of Service
                        </a>{" "}
                        apply.
                      </small>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {showScrollToTopButton && <ScrollToTopButton />}
    </div>
  );
};

export default Contact;
