import React from "react";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import classes from "./OurTeam.module.scss";

const OurTeam = () => {
  const websiteData = useSelector(
    (state) => state.websiteData.websiteData.team
  );

  return (
    <section className={`${classes["our-team-section"]} creme-container`}>
      <div className="zigzag-top" />
      <div className="zigzag-bottom" />
      <div className={classes["our-team-container"]}>
        <div className={classes["text-container"]}>
          <h2 className={"section-title"}>{websiteData.title}</h2>
          <p>{websiteData.text}</p>
        </div>

        <div className={classes["our-team-carousel"]}>
          <Swiper
            modules={[Pagination]}
            pagination={{ clickable: true }}
            breakpoints={{
              568: { slidesPerView: 2 },
              768: { slidesPerView: 1 },
              1024: { slidesPerView: 2 },
              1200: { slidesPerView: 3 },
              1400: { slidesPerView: 4 },
              1800: { slidesPerView: 5 },
              2200: { slidesPerView: 6 },
            }}
          >
            {websiteData.members.map((teamMember, i) => {
              return (
                <SwiperSlide key={i} className={`${classes["slide"]}`}>
                  <div className={classes.image}>
                    <img src={teamMember.imageUrl} alt="" />
                  </div>
                  <div className={classes["team-member-description"]}>
                    <h3>{teamMember.name}</h3>
                    <h4>{teamMember.title}</h4>
                    <h5>{teamMember.experience}</h5>
                    <p>{teamMember.description}</p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
