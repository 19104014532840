import React, { useState, useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import CareerItem from "../components/Careers/CareerItem";
import ScrollToTopButton from "../components/ScrollToTop/ScrollToTopButton";
import ReactGA from "react-ga4";

const Careers = () => {
    const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);

    const loaderData = useLoaderData();

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/careers",
            title: "Careers",
        });

        const handleScroll = () => {
            const scrollTop =
                window.pageYOffset || document.documentElement.scrollTop;
            setShowScrollToTopButton(scrollTop > 0);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            {loaderData.data.length ? (
                <>
                <div className="sub-navigation white-container">
                    <div className="zigzag-top" />
                    <div className="wrapper smaller">
                        <ul>
                            {loaderData.data.map((career) => (
                                <li key={career.id}>
                                    <a
                                        href={`#${career.title.replace(/\s/g, "").toLowerCase()}`}
                                        title={`${career.title}`}
                                    >
                                        {career.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <section className="careers creme-container">
                    <div className="zigzag-bottom" />
                    <div className="wrapper smaller">
                        {loaderData.data.map((career) => (
                            <CareerItem key={career.id} career={career} />
                        ))}
                    </div>
                    </section>
                </>
            ) : (
                    <p>
                        <i>No open career positions at this time.</i>
                    </p>
            )}
            {showScrollToTopButton && <ScrollToTopButton />}

        </>
    );

};

export default Careers;
