import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { config } from "../../common/Constants";
import "../../pages/Careers.scss";

const getInputValue = (target) => {
  if (target.name === "file") {
    return target.files;
  }
  return target.value;
};

const validations = {
  name: {
    name: "name",
    isNotValid: (name) => {
      const nameArr = name.trim().split(" ");
      const onlyLettersRegex = /^[A-Za-z\s]+$/;
      return (
        nameArr.length < 2 || !onlyLettersRegex.test(name) || name.length > 30
      );
    },
    message: "Please enter both first and last name.",
  },
  email: {
    name: "email",
    isNotValid: (email) => {
      return !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    },
    message: "Please enter a valid email address.",
  },
  phone: {
    name: "phone",
    isNotValid: (phone) => {
      return !phone.match(/^(0|\+359)(\s?\d){9}$/);
    },
    message: "Please enter a valid phone number.",
  },

  file: {
    name: "file",
    isNotValid: (files) => {
      if (!files || files.length === 0) {
        return true;
      }

      const allowedExtensions = ["pdf", "doc", "docx"];
      const invalidFiles = Array.from(files).filter(
        (file) =>
          !allowedExtensions.includes(file.name.split(".").pop().toLowerCase())
      );

      return invalidFiles.length > 0;
    },
    message: "Please upload a valid file (PDF, DOC, or DOCX).",
  },
};

const CareerItem = ({ career }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    return await executeRecaptcha("apply");
  };

  const [submittedForms, setSubmittedForms] = useState({});
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const formId = `career-form-${career.id}`;

  const submitData = async (careerId, data) => {
    setIsRequestPending(true);

    const recaptchaToken = await handleReCaptchaVerify();
    const url = `${config.API_URL}/careers/${careerId}/apply`;
    const response = await axios.post(url, data, {
      headers: { recaptcha: recaptchaToken },
    });

    setIsRequestPending(true);

    return response.data;
  };

  const validateForm = (form) => {
    let errors = {};

    const { name, email, phone, file } = form.elements;

    if (validations.name.isNotValid(name.value)) {
      errors[validations.name.name] = validations.name.message;
    }

    if (validations.email.isNotValid(email.value))
      errors[validations.email.name] = validations.email.message;

    if (validations.phone.isNotValid(phone.value))
      errors[validations.phone.name] = validations.phone.message;

    if (validations.file.isNotValid(file.files))
      errors[validations.file.name] = validations.file.message;

    return errors;
  };

  const handleBlur = (e) => {
    const fieldName = e.target.name;
    const fieldValidation = validations[fieldName];

    if (
      fieldValidation &&
      fieldValidation.isNotValid(getInputValue(e.target))
    ) {
      setFormErrors({
        [fieldName]: fieldValidation.message,
        ...formErrors,
      });
    }
  };

  const handleChange = (e) => {
    const fieldName = e.target.name;
    const fieldValidation = validations[fieldName];

    if (!fieldValidation) {
      return;
    }

    if (fieldValidation.isNotValid(getInputValue(e.target))) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: fieldValidation.message,
      }));
    } else {
      setFormErrors((prevErrors) => {
        const errors = { ...prevErrors };
        delete errors[fieldName];
        return errors;
      });
    }
  };

  const handleSubmit = async (careerId, e) => {
    e.preventDefault();

    const form = e.target;

    let errors = validateForm(form);

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      scrollToTop(formId);
      return;
    }

    const formData = new FormData();
    formData.append("name", form.elements.name.value);
    formData.append("email", form.elements.email.value);
    formData.append("phone", form.elements.phone.value);
    formData.append("portfolio", form.elements.portfolio.value);
    formData.append("message", form.elements.message.value);
    formData.append("file", form.elements.file.files[0]);

    await submitData(careerId, formData);

    setSubmittedForms((prevState) => ({
      ...prevState,
      [careerId]: true,
    }));
  };

  const scrollToTop = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const applyDisabled = isRequestPending || Object.keys(formErrors).length;

  return (
    <div
      key={career.id}
      className="career-container"
      id={`${career.title.replace(/\s/g, "").toLowerCase()}`}
    >
      <h2 className="section-title">{career.title}</h2>
      <div className="flex-container two-column">
        <div className="flex-item">
          {career.introduction.map((paragraph, index) => {
            return (
              <p className="indented-paragraph" key={index}>
                {paragraph}
              </p>
            );
          })}
          {career.responsibilities.length && (
            <p>
              <strong>
                <em>Responsibilities:</em>
              </strong>
              <ul className="indented-list">
                {career.responsibilities.map((responsibility, index) => {
                  return <li key={index}>{responsibility}</li>;
                })}
              </ul>
            </p>
          )}
          <p>
            <strong>
              <em>What we can provide in return:</em>
            </strong>
            <ul className="indented-list">
              {career.offers.map((offer, index) => {
                return <li key={index}>{offer}</li>;
              })}
            </ul>
          </p>
          <p>
            <strong>
              <em>Requirements:</em>
            </strong>
            <ul className="indented-list">
              {career.requirements.map((requirement, index) => {
                return <li key={index}>{requirement}</li>;
              })}
            </ul>
          </p>
          {career.nice_to_have.length && (
            <p>
              <strong>
                <em>Nice to have:</em>
              </strong>
              <ul className="indented-list">
                {career.nice_to_have.map((niceToHave, index) => {
                  return <li key={index}>{niceToHave}</li>;
                })}
              </ul>
            </p>
          )}
          {career.finalParagraph && (
            <p>
              <strong>{career.finalParagraph}</strong>
            </p>
          )}
        </div>
        <div className="flex-item">
          {submittedForms[career.id] ? (
            <div className={`submit-container`}>
              <div className="submit-title">
                <h3>Thank you for your application!</h3>
              </div>
              <div className="submit-content">
                <p>
                  We have sent a confirmation email to the address you provided.
                  We will review your application and will contact you if we
                  think you would be a good fit for the job.
                </p>
                <p>
                  In the meanwhile, would you like to have a look at the work we
                  produce for our clients:
                </p>
                <Link
                  to="/portfolio"
                  title="see our portfolio"
                  className="btn dark-purple"
                >
                  See our portfolio
                </Link>
              </div>
            </div>
          ) : (
            <div className="form" id={formId}>
              <h3 className="form-title">apply now:</h3>
              <form onSubmit={(e) => handleSubmit(career.id, e)}>
                <div className="form-field">
                  <label htmlFor="name">Your name</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Name Surname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {formErrors.name && (
                    <div className="error">{formErrors.name}</div>
                  )}
                </div>
                <div className="form-field">
                  <label htmlFor="email">Your email</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {formErrors.email && (
                    <div className="error">{formErrors.email}</div>
                  )}
                </div>
                <div className="form-field">
                  <label htmlFor="phone">Your telephone number</label>
                  <input
                    type="text"
                    name="phone"
                    placeholder="+359 ХХХ ХХХ ХХХ"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {formErrors.phone && (
                    <div className="error">{formErrors.phone}</div>
                  )}
                </div>
                <div className="form-field">
                  <label htmlFor="portfolio">Your online portfolio</label>
                  <input
                    type="text"
                    name="portfolio"
                    placeholder="Behance, Dribbble, personal website"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {formErrors.portfolio && (
                    <div className="error">{formErrors.portfolio}</div>
                  )}
                </div>
                <div className="form-field">
                  <label htmlFor="file" className="file-upload-label">
                    Upload your CV
                    <input
                      type="file"
                      name="file"
                      className="file-upload-input"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      accept=".pdf,.doc,.docx"
                    />
                  </label>
                  {formErrors.file && (
                    <div className="error">{formErrors.file}</div>
                  )}
                </div>
                <div className="form-field">
                  <label htmlFor="message">Leave a message</label>
                  <textarea
                    type="text"
                    name="message"
                    rows="10"
                    placeholder="Anything to add? Say it here."
                    maxLength="1000"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  ></textarea>
                  {formErrors.message && (
                    <div className="error">{formErrors.message}</div>
                  )}
                </div>
                <div className="form-field">
                  <input
                    disabled={applyDisabled}
                    type="submit"
                    value="Apply"
                    className={`btn ${
                      applyDisabled ? `disabled` : `dark-purple`
                    }`}
                  />
                </div>
              </form>
              <div>
                <small>
                  This site is protected by reCAPTCHA and the Google{" "}
                  <a href="https://policies.google.com/privacy">
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a href="https://policies.google.com/terms">
                    Terms of Service
                  </a>{" "}
                  apply.
                </small>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CareerItem;
