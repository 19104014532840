import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import classes from "./MainNavigation.module.scss";
import logo from "../../assets/img/logo.png";
import HeroContent from "../HeroContent/HeroContent";

const filterHeaderObjTabs = (headerObj) => {
  return {
    ...headerObj,
    tabs: headerObj.tabs.filter((tab) => tab.in_navigation),
  };
};

const MainNavigation = () => {
  const headerObj = filterHeaderObjTabs(
    useSelector((state) => state.websiteData.websiteData.header)
  );

  const [toggleBurger, setToggleBurger] = useState(false);
  const [headerNavClasses, setHeaderNavClasses] = useState(
    classes["header-nav"]
  );

  const handleToggleBurger = () => {
    setToggleBurger(!toggleBurger);

    setHeaderNavClasses(
      `${classes["header-nav"]} ${!toggleBurger ? classes.show : ""}`
    );

    if (!toggleBurger) {
      document.body.classList.add("fixed");
    } else {
      document.body.classList.remove("fixed");
    }
  };

  const closeBurger = () => {
    if (toggleBurger) {
      handleToggleBurger();
    }
  };

  console.error(headerObj.tabs)

  return (
    <>
      <div className={classes["header-container"]}>
        <div className={classes["header-logo"]}>
          <NavLink to="/" title="home">
            <img src={logo} alt="Home tab" />
          </NavLink>
        </div>

        <div
          onClick={handleToggleBurger}
          className={`burger-icon ${toggleBurger ? "open" : ""}`}
        >
          {headerObj.tabs.map((tab, i) => (
            <span key={i}></span>
          ))}
        </div>

        <div className={headerNavClasses}>
          <nav>
            <ul>
              {headerObj.tabs.map((tab, i) => (
                <li key={i}>
                  <NavLink
                    to={tab.path}
                    onClick={closeBurger}
                    className={({ isActive }) =>
                      isActive ? classes.active : ""
                    }
                  >
                    {tab.name.toUpperCase()}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className={`clearfix`}></div>
        <HeroContent heroContent={headerObj.heroContent}></HeroContent>
      </div>
    </>
  );
};

export default MainNavigation;
