import React from 'react';
import './ScrollToTopButton.scss';

const ScrollToTopButton = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button className="scroll-to-top-button btn dark-orange" onClick={handleScrollToTop}>
      <div className="arrow">&#8679;</div>
    </button>
  );
};

export default ScrollToTopButton;
