import React from "react";

const Social = (props) => {
  const { socialNetwork } = props;

  return (
    <li>
      <a
        title={socialNetwork.name}
        href={socialNetwork.path}
        target="_blank"
        rel="noopener noreferrer"
        className={`social-link ${socialNetwork.name.toLowerCase()}`}
      >
        {socialNetwork.name}
      </a>
    </li>
  );
};

export default Social;
