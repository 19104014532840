import React from "react";
import { useSelector } from "react-redux";

import classes from "./Statistics.module.scss";

import CountUp from "./CountUp";
// import statBg from "../../assets/img/statistics_bg.png";

const Statistics = () => {
  const websiteData = useSelector((state) => state.websiteData);
  const statistics = websiteData.websiteData.statistics;
  const statBg = statistics?.backgroundImage;

  return (
    <div
      className={classes["statistics-section"]}
      style={{ backgroundImage: `url(${statBg})` }}
    >
      <div className="wrapper">
        <ul>
          {statistics.statistics?.map((stat, i) => (
            <li key={i}>
              <CountUp
                start={0}
                end={stat.count}
                duration={60}
                measure={stat.measure}
              />
              <span className={classes.name}>{stat.title}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Statistics;
