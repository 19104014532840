import React from "react";
import classes from "../MainNavigation/MainNavigation.module.scss";
import { Link, useLocation } from "react-router-dom";

const HeroContent = (props) => {
  const currentPath = useLocation().pathname.split("/")[1];
  const { heroContent } = props;
  const title = currentPath === "" ? heroContent.title.replaceAll(/\s*\|\s*/g,"<br />")
                                   : currentPath.replace(/_/g, ' ');
  
  return (
    <div className={classes["hero-content-container"]}>
      <h1 dangerouslySetInnerHTML={{__html: title}} />
      {currentPath === "" && (
        <>
          <p>{heroContent.text}</p>
          <Link
            className={`${classes.btn} btn dark-orange`}
            to={heroContent.button.path}
          >
            {heroContent.button.text}
          </Link>
        </>
      )}
    </div>
  );
};

export default HeroContent;
