import React from "react";
import { Link } from "react-router-dom";
import classes from "./Footer.module.scss";
import { useSelector } from "react-redux";
import Social from "./Social";

const Footer = () => {
  const footerData = useSelector(
    (state) => state.websiteData.websiteData.footer
  );
  return (
    <>
      <div className={`wrapper clearfix`}>
        <div className={classes["footer-left-side"]}>
          <h2>Convinced already?</h2>
          <div>
            <Link
              to="contact"
              title="contact us"
              className={`btn dark-purple`}
            >
              contact us
            </Link>
          </div>
          {/* <div className={`flex-item`}>
            <Link
              to="portfolio"
              title="see our portfolio"
              className={`btn dark-purple`}
            >
              see our portfolio
            </Link>
          </div> */}
        </div>
        <div className={classes["footer-right-side"]}>
          <div className={classes["socials"]}>
            <ul>
              {footerData.socialNetworks.map((socialNetwork, i) => (
                <Social key={i} socialNetwork={socialNetwork} />
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className={classes["footer-info-links-container"]}>
        <div className={classes["footer-info-center"]}>
          <Link to="portfolio" title="Our Portfolio">Our Portfolio</Link>
          <Link to="terms_and_conditions" title="Terms and Conditions">
            Terms and Conditions
          </Link>
          <Link to="privacy_policy" title="Privacy Policy">
            Privacy Policy
          </Link>
          <div className={classes["footer-info-link-item"]}>
            <i>© Inkastle Studio 2023</i>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
