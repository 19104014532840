import React from "react";
import classes from "./AboutUs.module.scss";
import { useSelector } from "react-redux";
import ourClientsBg from "../../assets/img/clients_bg.png";
import "swiper/css/pagination";

const OurClients = () => {
  //TODO: To create aboutUs section side image
  const aboutUs = useSelector(
    (state) => state.websiteData.websiteData.header.heroContent.about_us
  );

  const ourClients = useSelector(
    (state) => state.websiteData.websiteData.ourClients
  );

  return (
    <section
      className={`${classes["our-clients-about-us-section"]} creme-container`}
      style={{ backgroundImage: `url(${ourClientsBg})` }}
    >
      <div className="zigzag-top" />
      <div className="zigzag-bottom" />
      <div className={`${classes.wrapper} wrapper`}>
        <h2 className="section-title">{aboutUs.title}</h2>
        <div className={`${classes.content}`}>
          <div className={`${classes["section-text"]}`}>
            {aboutUs.text.map((el) => (
              <p>{el}</p>
            ))}
          </div>
          <div className={classes["our-clients-character"]}>
            <img
              src={ourClients.section_side_img}
              alt="Character for our clients section"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurClients;
