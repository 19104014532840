import React, { useEffect, useState } from "react";
import classes from "./CookieConsent.module.scss";

const cookieConsentName = "cookieConsent";
const CookieConsent = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const hasConsent = getCookie(cookieConsentName);
    const timeout = setTimeout(() => {
      setIsOpen(!hasConsent);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  const handleClose = () => {
    document
      .querySelectorAll(
        `.${classes["cookie-consent-overlay"]}, .${classes["cookie-consent"]}`
      )
      .forEach((item) => {
        item.classList.add("cookie-consent-fadeout");
      });

    setTimeout(() => {
      setIsOpen(false);
    }, 800);
  };

  const handleConsent = () => {
    handleClose();
    setCookie(cookieConsentName, "true", 365);
  };

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  };

  const getCookie = (name) => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith(name))
      ?.split("=")[1];
    return cookieValue ? true : false;
  };

  return (
    <>
      {isOpen && (
        <div className={classes["cookie-consent"]}>
          <div className={classes["cookie-consent-content"]}>
            <h2>Cookie Consent</h2>
            <p>
              This website uses cookies to ensure you get the best experience on
              our website.
            </p>
            <button onClick={handleConsent} className="btn dark-orange">
              Accept
            </button>
          </div>
        </div>
      )}
      {isOpen && (
        <div
          className={classes["cookie-consent-overlay"]}
          onClick={handleClose}
        />
      )}

      {props.children}
    </>
  );
};

export default CookieConsent;
